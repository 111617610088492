import { GeoPoint, Timestamp } from "firebase/firestore"

export class Order {
  creatorId
  carrierId
  recipient
  alternate
  status
  process
  extra
  pickup
  pickupAddress
  dropoff
  dropoffAddress
  deliverable
  created
  expiration
  completed
  fragile

  constructor(
    creatorId: string,
    carrierId: string | null,
    recipient: string,
    alternate: string | null,
    status: "pending" | "active" | "completed" | "canceled",
    process: "pickup" | "dropoff" | "done",
    extra: string,
    pickup: GeoPoint,
    pickupAddress: string,
    dropoff: GeoPoint,
    dropoffAddress: string,
    deliverable: {
      height: number
      width: number
      depth: number
    },
    expiration: Timestamp,
    completed: Timestamp | null,
    fragile: boolean,
    created: Timestamp = Timestamp.now()
  ) {
    this.creatorId = creatorId
    this.carrierId = carrierId
    this.recipient = recipient
    this.alternate = alternate
    this.status = status
    this.process = process
    this.extra = extra
    this.pickup = pickup
    this.pickupAddress = pickupAddress
    this.dropoff = dropoff
    this.dropoffAddress = dropoffAddress
    this.deliverable = deliverable
    this.created = created
    this.expiration = expiration
    this.completed = completed
    this.fragile = fragile
  }

  data() {
    return {
      creatorId: this.creatorId,
      carrierId: this.carrierId,
      recipient: this.recipient,
      alternate: this.alternate,
      status: this.status,
      process: this.process,
      extra: this.extra,
      pickup: this.pickup,
      pickupAddress: this.pickupAddress,
      dropoff: this.dropoff,
      dropoffAddress: this.dropoffAddress,
      deliverable: this.deliverable,
      created: this.created,
      expiration: this.expiration,
      completed: this.completed,
      fragile: this.fragile,
    } as Order
  }
}