import { IonPage, IonContent, IonLabel, IonHeader, IonToolbar, IonButton, IonTitle, IonItem, IonInput, IonCheckbox, IonButtons, useIonAlert, useIonModal, useIonLoading, IonTextarea, IonText, IonIcon, IonListHeader, IonList, IonSelect, IonSelectOption, IonDatetime } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import { toast } from '../../../toast';
import { User } from 'firebase/auth';
import { GeoPoint, Timestamp } from 'firebase/firestore';
import SuitableCarriers from './components/SuitableCarriers';
import MapComponent from './components/MapComponent';
import { PresentCarrier } from '../../../data/presentCarrier';
import { Order } from '../../../data/order';
import { recipientNumberValidator, sizeValidator } from './utils/orderFormRegexCheck';
import PackagingGuide from './components/PackagingGuide';
import { mapOutline } from 'ionicons/icons';
import { addHours, addYears, setHours, setMinutes } from 'date-fns';



const NewOrderView: React.FC<{
    onDismiss: () => void,
    firestore: ExtendedFirestoreInstance,
    user: User
}> = ({ onDismiss, user, firestore }) => {

    const [present, dismiss] = useIonAlert();
    const [suitableCarriersList, setSuitableCarriersList] = useState<PresentCarrier[]>([])
    const [orderId, setOrderId] = useState('')
    const [loading, dismissLoading] = useIonLoading()

    const [presentNext, dismissNext] = useIonModal(SuitableCarriers, {
        onDismiss: () => {
            dismissNext()
            onDismiss()
        },
        carriers: suitableCarriersList,
        orderId: orderId,
        firestore
    })

    useEffect(() => {
        return () => dismissLoading()
    })

    class Location {
        constructor(
            public readonly coordinates: google.maps.LatLng,
            public readonly address: string)
        {}

        toString() {
            const lat = this.coordinates.lat();
            const lng = this.coordinates.lng();
            const latStr = lat >= 0 ? `${lat.toFixed(5)} N` : `${(-lat).toFixed(5)} S`;
            const lngStr = lng >= 0 ? `${lng.toFixed(5)} E` : `${(-lng).toFixed(5)} W`;
            return `${this.address} (${latStr}, ${lngStr})`;
        }
    }

    // // refs for from fields
    const pickup = useRef<Location>()
    const dropoff = useRef<Location>()
    const recipient = useRef('')
    const [alternate, setAlternate] = useState<string>('')
    const extra = useRef('')
    const height = useRef<number>(0)
    const width = useRef<number>(0)
    const depth = useRef<number>(0)
    const fragile = useRef<boolean>(false)

    const [expireDate, setExpireDate] = useState<string>();
    const [expireTime, setExpireTime] = useState<string>(setMinutes(setHours(new Date(), 17), 0).toISOString());
    const [expireDateTime, setExpireDateTime] = useState<Date>();
    useEffect(() => {
        if (expireDate !== undefined) {
            const date = new Date(expireDate);
            const time = new Date(expireTime);
            const dateTime = new Date(date.getFullYear(),
                                      date.getMonth(),
                                      date.getDate(),
                                      time.getHours(),
                                      time.getMinutes());
            const minDateTime = addHours(new Date(), 1);
            if (dateTime.valueOf() < minDateTime.valueOf()) {
                setExpireDate(minDateTime.toISOString());
                setExpireTime(minDateTime.toISOString());
                toast("Order expiration date and/or time adjusted.");
                setExpireDateTime(minDateTime);
            } else {
                setExpireDateTime(dateTime);
            }
        }
    }, [expireDate, expireTime]);

    const [selectPickupLocation, dismissSelectPickupLocation] = useIonModal(MapComponent, {
        dismiss: () => dismissSelectPickupLocation(),
        onLocationSelected: (location: google.maps.LatLng, address: string) => {
            console.debug('Selected pickup location', location)
            pickup.current = new Location(location, address)
        }
    })

    const [selectDropoffLocation, dismissSelectDropoffLocation] = useIonModal(MapComponent, {
        dismiss: () => dismissSelectDropoffLocation(),
        onLocationSelected: (location: google.maps.LatLng, address: string) => {
            console.debug('Selected dropoff location', location)
            dropoff.current = new Location(location, address)
        }
    })

    const handleForm = () => {
        // form check

        if (pickup.current === undefined) {
            toast("Pickup location not chosen")
            return
        }
        if (dropoff.current === undefined) {
            toast("Dropoff location not chosen")
            return
        }
        if (!recipientNumberValidator(recipient.current, 'Recipient number is invalid')) return
        if (!sizeValidator(height.current, 'Package heigh is invalid')) return
        if (!sizeValidator(width.current, 'Package width is invalid')) return
        if (!sizeValidator(depth.current, 'Package depth is invalid')) return

        if (expireDateTime === undefined) {
            toast("Missing order expiration date");
            return;
        }
        const minDateTime = addHours(new Date(), 1);
        if (expireDateTime.valueOf() < minDateTime.valueOf()) {
            toast("Order expiration date too near");
            return;
        }

        loading('Fetching suitable carriers', 15000, 'crescent')

        const order = new Order(
            user.uid,
            null,
            recipient.current,
            alternate,
            'pending',
            'pickup',
            extra.current,
            new GeoPoint(pickup.current.coordinates.lat(), pickup.current.coordinates.lng()),
            pickup.current.address,
            new GeoPoint(dropoff.current.coordinates.lat(), dropoff.current.coordinates.lng()),
            dropoff.current.address,
            {
                height: height.current,
                width: width.current,
                depth: depth.current
            },
            Timestamp.fromDate(expireDateTime),
            null,
            fragile.current,
            Timestamp.now() // created
        ).data()

        if (!user.uid) return

        // fetch('http://localhost:5001/transport-mobile-app/us-central1/webApi/newOrder', {
        fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS_WEBAPI_URL + 'newOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ order: order })
        })
            .then(res => res.json())
            .then((body: { carriers: PresentCarrier[], docId: string }) => {
                setSuitableCarriersList(body.carriers)
                setOrderId(body.docId)
            })
            .then(() => {
                presentNext()
            })
            .catch((err) => toast(`${err}`))
            .finally(() => dismissLoading())
    }

    const [packagingGuide, dismissGuide] = useIonModal(PackagingGuide, {
        onDismiss: () => dismissGuide(),
    })

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonItem lines="none">
                        <IonButtons slot="start">

                            <IonButton
                                color="danger"
                                fill='solid'
                                shape='round'
                                onClick={() =>
                                    present({
                                        cssClass: 'my-css',
                                        header: 'Are you sure you want to cancel the process?',
                                        message: 'Cancelling the process will erase all the data added.',
                                        buttons: [
                                            { text: 'YES', handler: () => onDismiss() },
                                            { text: 'NO', handler: () => console.log('The ordering process will continue.') },
                                        ],
                                        onDidDismiss: () => dismiss(),
                                    })
                                }
                            >Cancel</IonButton>

                        </IonButtons>
                        <IonTitle class="ion-text-center ion-no-padding" size="large">New Order</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                color='primary'
                                fill='solid'
                                shape='round'
                                onClick={() => handleForm()}
                            >Next</IonButton>
                        </IonButtons>
                    </IonItem>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonList>
                    <IonListHeader>Transfer details:</IonListHeader>


                    <IonItem>
                        <IonLabel position="stacked">Pick-up location:</IonLabel>
                        <IonText
                            color={pickup.current ? "dark": "medium"}
                            style={{marginTop: pickup.current ? "0.5em" : "0"}}
                        >
                            {pickup.current ? pickup.current.toString() : "Choose location"}
                        </IonText>
                        <IonButtons slot='end'>
                            <IonButton onClick={() => selectPickupLocation()}>
                                <IonIcon slot="icon-only" icon={mapOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Drop-off location:</IonLabel>
                        <IonText
                            color={dropoff.current ? "dark": "medium"}
                            style={{marginTop: dropoff.current ? "0.5em" : "0"}}
                        >
                            {dropoff.current ? dropoff.current.toString() : "Choose location"}
                        </IonText>
                        <IonButtons slot='end'>
                            <IonButton onClick={() => selectDropoffLocation()}>
                                <IonIcon slot="icon-only" icon={mapOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Receiver phone number:</IonLabel>
                        <IonInput type='tel' placeholder="Enter phone number" value={recipient.current} onIonChange={(e) => recipient.current = e.detail.value!}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Alternative delivery method:</IonLabel>
                        {/* <IonTextarea value={alternate.current} onIonChange={e => alternate.current = e.detail.value!} /> */}

                        <IonSelect
                            value={alternate}
                            placeholder="select alternate delivery method"
                            onIonChange={e => setAlternate(e.detail.value!)}
                            multiple={false}
                            interface="action-sheet"
                        >
                            <IonSelectOption value="at-door">Leave at the door</IonSelectOption>
                            <IonSelectOption value="neighbour">Hand to the neighbours</IonSelectOption>
                            <IonSelectOption value="parcel-machine">Leave to nearest parcel machine</IonSelectOption>
                            <IonSelectOption value="next-time">Leave to nearest parcel machine</IonSelectOption>
                        </IonSelect>

                    </IonItem>
                    <IonItem>
                        <IonLabel position='stacked'>Extra information about alternative delivery option:</IonLabel>
                        <IonTextarea value={extra.current} onIonChange={e => extra.current = e.detail.value!} />
                    </IonItem>


                </IonList>
                <IonList>
                    <IonListHeader>Package details:</IonListHeader>


                    <IonButton
                        size="small" fill="outline" expand="block"
                        onClick={() => packagingGuide()}
                        class="ion-margin-horizontal"
                    >
                        See Packaging Guide
                    </IonButton>

                    <IonItem>
                        <IonLabel position="stacked">Height of the package (cm):</IonLabel>
                        <IonInput value={height.current} onIonChange={e => height.current = Number(e.detail.value)} />
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Width of the package (cm):</IonLabel>
                        <IonInput value={width.current} onIonChange={e => width.current = Number(e.detail.value)} />
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Depth of the package (cm):</IonLabel>
                        <IonInput value={depth.current} onIonChange={e => depth.current = Number(e.detail.value)} />
                    </IonItem>

                    <IonItem>
                        <IonLabel>Package is fragile:</IonLabel>
                        <IonCheckbox
                            checked={fragile.current}
                            onIonChange={e => fragile.current = e.detail.value!}
                        />
                    </IonItem>


                </IonList>
                <IonList>
                    <IonListHeader>Other details:</IonListHeader>


                    <IonItem>
                        <IonLabel position="stacked">Order expiration date:</IonLabel>
                        <IonDatetime
                            placeholder="Please select order expiration date"
                            value={expireDate}
                            onIonChange={e => setExpireDate(e.detail.value!)}
                            max={addYears(new Date(), 1).toISOString()}
                            min={addHours(new Date(), 1).toISOString()}
                            />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Order expiration time:</IonLabel>
                        <IonDatetime
                            value={expireTime}
                            onIonChange={e => setExpireTime(e.detail.value!)}
                            displayFormat="HH:mm"
                            />
                    </IonItem>


                </IonList>
            </IonContent>
        </IonPage>
    )
}


export default NewOrderView