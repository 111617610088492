import { GeoPoint, Timestamp } from "firebase/firestore"

export class Route {
  from
  to
  middlePoints
  wayPoints
  startDateTime
  endDateTime
  detourAmount
  maxDeliveries
  maxPackageWidth
  maxPackageHeight
  maxPackageDepth

  constructor(
    from: GeoPoint,
    to: GeoPoint,
    middlePoints: GeoPoint[],
    wayPoints: GeoPoint[],
    startDateTime: Timestamp,
    endDateTime: Timestamp,
    detourAmount: number,
    maxDeliveries: number,
    maxPackageWidth: number,
    maxPackageHeight: number,
    maxPackageDepth: number,
  ) {
    this.from = from
    this.to = to
    this.middlePoints = middlePoints
    this.wayPoints = wayPoints
    this.startDateTime = startDateTime
    this.endDateTime = endDateTime
    this.detourAmount = detourAmount
    this.maxDeliveries = maxDeliveries
    this.maxPackageWidth = maxPackageWidth
    this.maxPackageHeight = maxPackageHeight
    this.maxPackageDepth = maxPackageDepth
  }

  data() {
    return {
      from: this.from,
      to: this.to,
      middlePoints: this.middlePoints,
      wayPoints: this.wayPoints,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      detourAmount: this.detourAmount,
      maxDeliveries: this.maxDeliveries,
      maxPackageWidth: this.maxPackageWidth,
      maxPackageHeight: this.maxPackageHeight,
      maxPackageDepth: this.maxPackageDepth,
    } as Route
  }
}