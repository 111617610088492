import {
    IonPage,
    IonContent,
    IonButton,
    IonTitle,
    IonItem,
    IonLabel,
    IonInput,
    IonItemDivider,
    IonItemGroup,
    IonHeader,
    IonToolbar,
    useIonAlert,
    useIonModal,
} from '@ionic/react'
import { EmailAuthProvider } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { toast } from '../../../toast';
import { sendEmailVerification } from "firebase/auth"
import { Profile } from '../../../data/profile';
import '../../css/ProfilePage.css'
import CarrierSetup from '../carrier/components/CarrierSetup';
import ProfileRouteItem from './components/ProfileRouteItem';

const ProfilePage: React.FC = () => {
    const firebase = useFirebase()
    const firestore = useFirestore()
    const history = useHistory()

    const { auth, profile }: { auth: any, profile: Profile } = useSelector((state: any) => state.firebase)
    const user = firebase.auth().currentUser
    const userDbRef = firestore.collection('users').doc(auth.uid)

    const [confBtnText, setConfBtnText] = useState('Send confirmation link')
    const [present, dismissAlert] = useIonAlert();

    const [carrierSetup, dismissSetup] = useIonModal(CarrierSetup, {
        onDismiss: () => dismissSetup(),
        auth,
        firestore
    })

    const [routes, setRoutes] = useState([{
        id: 0,
        from: 'not found',
        to: 'not found'
    }])

    useEffect(() => {
        if (!profile.carrier)
            return
        if (profile.carrier.routes.length === 0)
            return

        setRoutes(routes => [])
        profile.carrier.routes.map((route: any, index: number) => (
            convertAddress(index, route.from, route.to)
        ))
    }, [profile])

    const emailRef = useRef(auth ? auth.email : 'Not Logged in!')
    const firstName = useRef(auth ? profile.firstName : 'Not Logged in!')
    const lastName = useRef(auth ? profile.lastName : 'Not Logged in!')
    const userName = useRef(auth ? profile.userName : 'Not Logged in!')
    const phoneNumber = useRef(auth ? profile.phoneNumber : 'Not Logged in!')
    const oldPasswordRef = useRef('')
    const newPasswordRef = useRef('')
    const newPasswordConfRef = useRef('')

    const emailCheck = () => /^[^\s]+@[a-zA-Z]+\.[a-zA-Z]{2,3}$/.test(emailRef.current)
    const firstNameCheck = () => /^([a-zA-ZüõöäÜÕÖÄ]([-\s][a-zA-ZüõöäÜÕÖÄ]+)*)+$/.test(firstName.current)
    const lastNameCheck = () => /^([a-zA-ZüõöäÜÕÖÄ]([-\s][a-zA-ZüõöäÜÕÖÄ]+)*)+$/.test(lastName.current)
    const userNameCheck = () => /^([a-zA-ZüõöäÜÕÖÄ0-9]([-][a-zA-ZüõöäÜÕÖÄ0-9]+)*)+$/.test(userName.current)
    const phoneNumberCheck = () => /^((\+)?(\d{1,15}))$/.test(phoneNumber.current)
    const passwordCheck = () => newPasswordRef.current === newPasswordConfRef.current

    function updateProfile() {
        if (!firstNameCheck()) return toast('First name is invalid', 3000)
        if (!lastNameCheck()) return toast('Last name is invalid', 3000)
        if (!userNameCheck()) return toast('Username is invalid', 3000)
        if (!phoneNumberCheck()) return toast('Number is invalid', 3000)

        user!.updateProfile({ displayName: userName.current }).then(() => {
            userDbRef.update({
                firstName: firstName.current,
                lastName: lastName.current,
                userName: userName.current,
                phoneNumber: phoneNumber.current,
            })
        }).then(() => {
            firebase.reloadAuth()
            toast(`Profile data has been successfully updated`, 3000)
        })
        .catch((e) => toast(`Error updating profile: ${e.message}`, 3000))
    }

    function deleteUserAccount() {
        present({
            cssClass: 'my-css',
            header: 'Are you sure you want to delete your account?',
            message: 'All your data will lost. Enter your password below if you wish to proceed.',
            inputs: [{
                name: 'passwordInput',
                type: 'password',
                placeholder: 'Password',
            }],
            buttons: [{
                text: 'Delete', handler: (data) => {
                    user?.reauthenticateWithCredential(EmailAuthProvider.credential(auth.email, data.passwordInput))
                        .then(() => {
                            user?.delete()
                                .then(() => userDbRef.delete())
                                .then(() => history.push('/'))
                                .then(() => toast(`Your account was successfully deleted`, 3000))
                                .catch((e) => toast(`Error deleting user: ${e.message}`, 3000))
                        })
                        .catch((e) => toast(`Error: ${e.message}`, 3000))
                    dismissAlert();
                }
            }, 'Cancel',
            ],
        })
    }

    function changeEmail() {
        if (!user!.emailVerified) {
            sendEmailVerification(user!)
            return toast('Verification link has been sent. Please check your email', 3000)
        } else setConfBtnText('Send confirmation link')
        if (emailRef.current === auth.email) return toast('You are already using this email', 3000)
        if (!emailCheck()) return toast('Email is invalid', 3000)
        present({
            cssClass: 'my-css',
            header: 'Enter your password below to change your email',
            inputs: [{
                name: 'passwordInput',
                type: 'password',
                placeholder: 'Password',
            }],
            buttons: ['Cancel', {
                text: 'Confirm', handler: (data) => {
                    user?.reauthenticateWithCredential(EmailAuthProvider.credential(auth.email, data.passwordInput))
                        .then(() => {
                            user?.updateEmail(emailRef.current).then(() => {
                                sendEmailVerification(user)
                                setConfBtnText('Resend confirmation link')
                                toast('Verification link has been sent. Please check your email', 3000)
                            }).catch((e: any) => toast(`Error: ${e.message}`, 3000))
                        })
                        .catch((e: any) => toast(`Invalid password`, 3000))
                    dismissAlert();
                }
            }],
        })
    }

    function changePassword() {
        user?.reauthenticateWithCredential(EmailAuthProvider.credential(auth.email, oldPasswordRef.current))
            .then(() => {
                if (!passwordCheck()) return toast('Passwords do not match', 3000)
                user.updatePassword(newPasswordRef.current)
                    .then(() => {
                        oldPasswordRef.current = ''
                        newPasswordRef.current = ''
                        newPasswordConfRef.current = ''
                        toast(`Password successfully updated`, 3000)
                    }).catch((e: any) => toast(`Error: ${e.message}`, 3000))
            })
            .catch((e: any) => toast('Invalid password', 3000))
    }

    async function convertAddress(index: number, fromPosition: any, toPosition: any) {
        const controller = new AbortController();
        const { signal } = controller;

        var route: any = {
            id: index,
            from: '-',
            to: '-'
        };

        await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng="
            + fromPosition._lat + "," + fromPosition._long
            + "&key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            { signal })
            .then(response => response.json())
            .then(json => route.from = json.results[0].formatted_address)
            .catch((e) => {
                console.debug("Error fetching reverse geocode:", e);
            })

        await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng="
            + toPosition._lat + "," + toPosition._long
            + "&key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            { signal })
            .then(response => response.json())
            .then(json => route.to = json.results[0].formatted_address)
            .catch((e) => {
                console.debug("Error fetching reverse geocode:", e);
            })

        setRoutes(routes => [...routes, route])
    }

    function getCarrierRoutes() {
        if (!profile.carrier)
            return <IonItem lines='full'><p>No carrier data</p></IonItem>
        if (profile.carrier.routes.length == 0)
            return <IonItem lines='full'><p>No routes have been added yet</p></IonItem>

        const routesItem = routes.map((route) => (
            <ProfileRouteItem key={route.id} route={route} deleteRoute={ () => deleteRoute(route.id)} />
        ))
        return routesItem;
    }

    function deleteRoute(index: number) {
        profile.carrier.routes.splice(index, 1);

        userDbRef.update({
            'carrier.routes': profile.carrier.routes
        })
            .then(() => toast(`The route have been successfully deleted`, 3000))
            .catch((e) => toast(`Error deleting the route: ${e.message}`, 3000));
    }


    if (!user || !auth) {
        return <IonPage>Loading...</IonPage>
    }
    else return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle className='ion-text-uppercase ion-text-center ion-padding'>Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItemGroup>
                    <IonItemDivider className='ion-padding-top'>
                        <IonTitle className='ion-no-padding' color='dark'>Personal information</IonTitle>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel position="stacked" color='medium'>Username</IonLabel>
                        <IonInput
                            type='text'
                            value={auth ? auth.displayName : 'Not Logged in!'}
                            onIonChange={(e) => userName.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" color='medium'>First name</IonLabel>
                        <IonInput
                            type='text'
                            value={auth ? profile.firstName : 'Not Logged in!'}
                            onIonChange={(e) => firstName.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" color='medium'>Last name</IonLabel>
                        <IonInput
                            type='text'
                            value={auth ? profile.lastName : 'Not Logged in!'}
                            onIonChange={(e) => lastName.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" color='medium'>Tel</IonLabel>
                        <IonInput
                            type='tel'
                            value={auth ? profile.phoneNumber : 'Not Logged in!'}
                            onIonChange={(e) => phoneNumber.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonButton className='ion-padding' expand='block' onClick={() => updateProfile()}>
                        Save profile changes
                    </IonButton>
                </IonItemGroup>

                <IonItemGroup className='ion-padding-top'>
                    <IonItemDivider className='ion-padding-top'>
                        <IonTitle className='ion-no-padding' color='dark'>Routes</IonTitle>
                    </IonItemDivider>
                    {getCarrierRoutes()}
                    <IonButton className='ion-padding' expand='block' onClick={() => carrierSetup()}>
                        Add new route
                    </IonButton>
                </IonItemGroup>

                <IonItemGroup className='ion-padding-top'>
                    <IonItemDivider className='ion-padding-top'>
                        <IonTitle className='ion-no-padding' color='dark'>Email change</IonTitle>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel position="stacked" color='medium'>Email</IonLabel>
                        <IonInput
                            type='email'
                            value={auth ? auth.email : 'Not Logged in!'}
                            onIonChange={(e) => emailRef.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonButton className='ion-padding' expand='block' onClick={changeEmail}>{confBtnText}</IonButton>
                </IonItemGroup>

                <IonItemGroup className='ion-padding-top'>
                    <IonItemDivider className='ion-padding-top'>
                        <IonTitle className='ion-no-padding' color='dark'>Password change</IonTitle>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel position="floating" color='medium'>Current password</IonLabel>
                        <IonInput type='password' onIonChange={(e) => oldPasswordRef.current = e.detail.value!} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color='medium'>New password</IonLabel>
                        <IonInput type='password' onIonChange={(e) => newPasswordRef.current = e.detail.value!} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color='medium'>Confirm password</IonLabel>
                        <IonInput type='password' onIonChange={(e) => newPasswordConfRef.current = e.detail.value!} />
                    </IonItem>
                    <IonButton className='ion-padding' expand='block' onClick={changePassword}>Change password</IonButton>
                </IonItemGroup>

                <IonItemGroup className='ion-padding-top'>
                    <IonItemDivider className='ion-padding-top'>
                        <IonTitle className='ion-no-padding' color='dark'>Delete account</IonTitle>
                    </IonItemDivider>
                    <p className='ion-text-center ion-padding-horizontal ion-padding-top ion-no-margin'>
                        Warning! If you delete your account you will lose your TMA credentials and will
                        no longer be able to use TMA app.<br></br>Data from deleted accounts cannot be restored.
                    </p>
                    <IonButton className='ion-padding' expand='block' color='danger' fill='solid' onClick={deleteUserAccount}>
                        Delete account
                    </IonButton>
                </IonItemGroup>

                <IonItemGroup className='ion-padding-top'>
                    <IonButton className='ion-padding' expand='block' color='danger' fill='outline' onClick={() => {
                        firebase.logout().then(() => history.push('/'))
                    }}>Sign out</IonButton>
                </IonItemGroup>

            </IonContent>
        </IonPage>
    )
}

export default ProfilePage
