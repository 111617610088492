import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  useIonModal,
} from "@ionic/react";
import CarrierRecommendation from "./CarrierRecommendation";
import { PresentCarrier } from "../../../../data/presentCarrier";
import CarrierItem from "./CarrierItem";
import { ExtendedFirestoreInstance } from "react-redux-firebase";

const SuitableCarriers: React.FC<{
  onDismiss: () => void;
  carriers: PresentCarrier[];
  orderId: string,
  firestore: ExtendedFirestoreInstance
}> = ({ onDismiss, carriers, orderId, firestore }) => {


  const [selectedCarrier, setSelectedCarrier] = useState<PresentCarrier | null>(null)
  const [presentCarrier, dismissCarrier] = useIonModal(CarrierItem, {
    carrier: selectedCarrier,
    orderId,
    onDismiss: () => dismissCarrier(),
    onComplete: () => {
      dismissCarrier()
      onDismiss()
    },
    firestore
  })

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonButtons slot="start">
                    <IonButton
                      color="danger"
                      fill="solid"
                      shape="round"
                      onClick={() => onDismiss()}
                    >
                      Cancel
                    </IonButton>
                  </IonButtons>
                  <IonTitle class="ion-text-center" size="large">
                    Suitable Carriers
                  </IonTitle>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          {
              carriers ? carriers.length !== 0 ? (
                carriers.map((carrier, key) => {
                  return (
                    <CarrierRecommendation
                      key={key}
                      showCarrier={ () => {
                        setSelectedCarrier(carrier)
                        presentCarrier()
                      } }
                      carrier={carrier}
                    />
                  );
                })
              ) : (
                <IonTitle>
                  <h2>No suitable carriers</h2>
                </IonTitle>
              )
              :
              <h2>No Suitable carriers</h2>
          }
        </IonList>
      </IonContent>
    </>
  );
};

export default SuitableCarriers;
