import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonNote, IonPage, IonTitle, IonToolbar, useIonModal } from "@ionic/react"
import { addYears, compareAsc, setHours, setMinutes } from "date-fns"
import { arrayUnion, GeoPoint, Timestamp } from "firebase/firestore"
import React, { useEffect, useRef, useState } from "react"
import { ExtendedFirestoreInstance } from "react-redux-firebase"
import { Route } from "../../../../data/route"
import { toast } from "../../../../toast"
import RouteItem from "./RouteItem"
import RouteSetup from "./RouteSetup"



const CarrierSetup: React.FC<{
    onDismiss: () => void,
    auth: any,
    firestore: ExtendedFirestoreInstance
}> = ({onDismiss, auth, firestore}) => {

    const maxDeliveries = useRef(3)
    const maxDeviation = useRef(5)

    const width = useRef(10)
    const height = useRef(10)
    const depth = useRef(10)

    const [startDate, setStartDate] = useState<string>();
    const [startTime, setStartTime] = useState<string>(setMinutes(setHours(new Date(), 10), 0).toISOString());
    const [startDateTime, setStartDateTime] = useState<Date>();
    useEffect(() => {
        if (startDate !== undefined) {
            const date = new Date(startDate);
            const time = new Date(startTime);
            const dateTime = new Date(date.getFullYear(),
                                      date.getMonth(),
                                      date.getDate(),
                                      time.getHours(),
                                      time.getMinutes());
            setStartDateTime(dateTime);
        }
    }, [startDate, startTime]);

    const [endDate, setEndDate] = useState<string>();
    const [endTime, setEndTime] = useState<string>(setMinutes(setHours(new Date(), 12), 0).toISOString());
    const [endDateTime, setEndDateTime] = useState<Date>();
    useEffect(() => {
        if (endDate !== undefined) {
            const date = new Date(endDate);
            const time = new Date(endTime);
            const dateTime = new Date(date.getFullYear(),
                                      date.getMonth(),
                                      date.getDate(),
                                      time.getHours(),
                                      time.getMinutes());
            setEndDateTime(dateTime);
        }
    }, [endDate, endTime]);

    const [newRoute, setNewRoute] = useState<{
        waypoints: google.maps.LatLng[],
        route: google.maps.LatLng[] | null,
        currentIndex: number}>()
    useEffect(() => {}, [newRoute])

    const [show, dismiss] = useIonModal(RouteSetup, {
        dismiss: () => dismiss(),
        saveRoute: (data: any) => {
            console.debug('Selected route data', data)
            setNewRoute(data)
        }
    })

    const finish = () => {
        console.debug('time: ', startTime, endTime )

        if (!newRoute) return toast('No Route selected', 3000)
        else if (startDateTime === undefined
        ) return toast('Route start date not given', 3000)
        else if (endDateTime === undefined
        ) return toast('Route end date not given', 3000)
        else if (compareAsc(new Date(), startDateTime) > 0
        ) return toast('Route start date and time may not be in the past', 3000)
        else if (compareAsc(startDateTime, endDateTime) > 0
        ) return toast('Route start date and time may not be later than route end date and time', 3000)
        else {
            const route = newRoute.route!.map((point) => new GeoPoint(point.lat(), point.lng()))
            const waypoints = newRoute.waypoints.map((point) => new GeoPoint(point.lat(), point.lng()))

            const start = route[0]
            const end = route[route.length - 1]
            const middlePoints = route.length === 2 ? [] : route.slice(1, route.length - 1)

            console.debug('middlepoints', middlePoints)

            const newRouteData =
                    new Route(
                        start,
                        end,
                        middlePoints,
                        waypoints,
                        Timestamp.fromDate(startDateTime),
                        Timestamp.fromDate(endDateTime),
                        maxDeviation.current,
                        maxDeliveries.current,
                        width.current,
                        height.current,
                        depth.current,
                        ).data();
                        
            firestore.collection('users').doc(auth.uid).update({
                "carrier.routes": arrayUnion(newRouteData),
            })
            .then(() => onDismiss())
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonItem lines="none">
                        <IonButtons slot="start">
                            <IonButton
                                color="danger"
                                fill='solid'
                                shape='round'
                                onClick={() => onDismiss()}
                            >Cancel</IonButton>
                        </IonButtons>
                        <IonTitle class="ion-text-center" size="large">Add route</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                color='primary'
                                fill='solid'
                                shape='round'
                                disabled={!newRoute}
                                onClick={() => finish()}
                            >Add</IonButton>
                        </IonButtons>
                    </IonItem>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonItemDivider className='ion-padding'>
                    <IonTitle className='ion-no-padding' color='dark'>Route</IonTitle>
                    {
                        !newRoute ? 
                        <IonButton slot='end' onClick={() => show()} fill='solid'>New Route</IonButton>
                        :
                        <IonButton slot='end' onClick={() => setNewRoute(undefined)} color='danger' fill='outline'>Clear Route</IonButton>
                    }
                </IonItemDivider>

                {
                    (() => {
                        if (newRoute) return (
                            <IonContent className='ion-padding' style={{
                                height: "50%"
                            }}>
                                <RouteItem
                                    route={newRoute}
                                    disabled={true}
                                />
                            </IonContent>
                        )
                        else return (
                            <IonItem lines='none'>
                                <IonLabel>No Route added</IonLabel>
                            </IonItem>
                        )
                    })()
                }

                {
                    (() => {
                        if (newRoute) return (
                            <IonList>
                                <IonItemDivider className='ion-padding'>
                                    <IonTitle className='ion-no-padding' color='dark'>Route schedule</IonTitle>
                                </IonItemDivider>

                                <IonItem>
                                    <IonLabel position="stacked">Route start date:</IonLabel>
                                    <IonDatetime
                                        placeholder="Please select route start date"
                                        value={startDate}
                                        onIonChange={e => setStartDate(e.detail.value!)}
                                        max={addYears(new Date(), 1).toISOString()}
                                        min={new Date().toISOString()}
                                        />
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked">Route start time:</IonLabel>
                                    <IonDatetime
                                        value={startTime}
                                        onIonChange={e => setStartTime(e.detail.value!)}
                                        displayFormat="HH:mm"
                                        />
                                </IonItem>

                                <IonItem>
                                    <IonLabel position="stacked">Route end date:</IonLabel>
                                    <IonDatetime
                                        placeholder="Please select route end date"
                                        value={endDate}
                                        onIonChange={e => setEndDate(e.detail.value!)}
                                        max={addYears(new Date(), 1).toISOString()}
                                        min={new Date().toISOString()}
                                        />
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked">Route end time:</IonLabel>
                                    <IonDatetime
                                        value={endTime}
                                        onIonChange={e => setEndTime(e.detail.value!)}
                                        displayFormat="HH:mm"
                                        />
                                </IonItem>

                                <IonItemDivider>
                                    <IonTitle className='ion-no-padding' color='dark'>Details</IonTitle>
                                </IonItemDivider>
                                    
                                <IonItem lines='inset'>
                                    <IonLabel position='floating' >Max simultaneous deliveries</IonLabel>
                                    <IonInput
                                        type='number' min='1' max='10'
                                        value={maxDeliveries.current}
                                        onIonChange={e => maxDeliveries.current = Number(e.detail.value)}
                                    />
                                </IonItem>
                                <IonItem lines='inset'>
                                    <IonLabel position='floating' >Max deviation amount in km</IonLabel>
                                    <IonInput
                                        type='number' min='1'
                                        value={maxDeviation.current}
                                        onIonChange={e => maxDeviation.current = Number(e.detail.value)}
                                    />
                                </IonItem>

                                <IonItemDivider className='ion-padding'>
                                    <IonTitle className='ion-no-padding' color='dark'>Max package size</IonTitle>
                                </IonItemDivider>

                                <IonItem lines='inset'>
                                    <IonLabel position='floating' >Width</IonLabel>
                                    <IonInput
                                        type='number' min='1'
                                        value={width.current}
                                        onIonChange={e => width.current = Number(e.detail.value)}
                                    />
                                </IonItem>
                                <IonItem lines='inset'>
                                    <IonLabel position='floating' >Height</IonLabel>
                                    <IonInput
                                        type='number' min='1'
                                        value={height.current}
                                        onIonChange={e => height.current = Number(e.detail.value)}
                                    />
                                </IonItem>
                                <IonItem lines='inset'>
                                    <IonLabel position='floating' >Depth</IonLabel>
                                    <IonInput
                                        type='number' min='1'
                                        value={depth.current}
                                        onIonChange={e => depth.current = Number(e.detail.value)}
                                    />
                                </IonItem>
                            </IonList>
                        )
                        else return (
                            <IonItem className='ion-padding' lines='none'>
                                <IonNote>Add a route to continue</IonNote>
                            </IonItem>
                        )
                    })()
                }
            </IonContent>
        </IonPage>
    )
}

export default CarrierSetup
