import { IonButton, IonCol, IonGrid, IonHeader, IonItem, IonRow, IonTitle, IonToolbar, IonLoading, IonIcon, IonButtons, IonToast } from "@ionic/react"
import React, { useRef, useState, useEffect } from "react"
import { trashOutline } from 'ionicons/icons';
import { toast } from "../../../../toast";

// See https://developers.google.com/maps/documentation/javascript/react-map

interface RouteSetupMarkerProps extends google.maps.MarkerOptions {
    onDragStart?: (e: google.maps.MapMouseEvent) => void,
    onDragEnd?: (e: google.maps.MapMouseEvent) => void,
    onClick?: (e: google.maps.MapMouseEvent) => void,
}

const RouteSetupMarker: React.FC<RouteSetupMarkerProps> = (options: RouteSetupMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        }
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            ['click', 'dragend', 'dragstart'].forEach(eventName => google.maps.event.clearListeners(marker, eventName));
            if (options.onClick) {
                marker.addListener('click', options.onClick);
            }
            if (options.onDragEnd) {
                marker.addListener('dragend', options.onDragEnd);
            }
            if (options.onDragStart) {
                marker.addListener('dragstart', options.onDragStart);
            }
        }
    }, [marker, options]);

    return null;
};

const RouteSetupPolyline: React.FC<google.maps.PolylineOptions> = (options: google.maps.PolylineOptions) => {
    const [polyline, setPolyline] = useState<google.maps.Polyline>();

    useEffect(() => {
        if (!polyline) {
            setPolyline(new google.maps.Polyline());
        }
        return () => {
            if (polyline) {
                polyline.setMap(null);
            }
        }
    }, [polyline]);

    useEffect(() => {
        if (polyline) {
            polyline.setOptions(options);
        }
    }, [polyline, options]);

    return null;
};

interface RouteSetupMapProps extends google.maps.MapOptions {
    onClick?: (e: google.maps.MapMouseEvent) => void,
}

const RouteSetupMap: React.FC<RouteSetupMapProps> = ({
    onClick,
    children,
    ...options
}) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (mapContainer.current && !map) {
            setMap(new google.maps.Map(mapContainer.current, options));
        }
    }, [mapContainer, map]);

    useEffect(() => {
        if (map) {
            ['click'].forEach(eventName => google.maps.event.clearListeners(map, eventName));
            if (onClick) {
                map.addListener('click', onClick);
            }
        }
    }, [map, onClick]);

    return <>
        <div className="map-container" ref={mapContainer}></div>
        {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {map});
            }
        })}
    </>;
};

interface RouteSetupState {
    waypoints: google.maps.LatLng[],
    route: google.maps.LatLng[] | null,
    currentIndex: number,
};

const RouteSetup: React.FC<{
    dismiss: () => void,
    saveRoute: (data: RouteSetupState) => void
}> = ({dismiss, saveRoute}) => {

    const initialMapOptions = {
        center: { lat: 58.6454381, lng: 25.4247266 },
        zoom: 7.88,
        disableDefaultUI: true,
        clickableIcons: false,
    };
    const [setupState, setSetupState] = useState<RouteSetupState>({
        waypoints: [],
        route: null,
        currentIndex: 0
    });
    const [toastIsVisible, setToastVisible] = useState(true);

    useEffect(() => {
        setToastVisible(setupState.waypoints.length <= 0);
        return () => setToastVisible(false);
    }, [setupState]);

    const onMapClick = (event: google.maps.MapMouseEvent) => {
        if (setupState.waypoints.length <= 0) {
            setSetupState({...setupState, waypoints: [event.latLng!], currentIndex: 0});
        } else if (setupState.waypoints.length >= 10) {
            toast("No more than 10 waypoints allowed!");
        } else {
            setSetupState({
                ...setupState,
                waypoints: [
                    ...setupState.waypoints.slice(0, setupState.currentIndex + 1),
                    event.latLng!,
                    ...setupState.waypoints.slice(setupState.currentIndex + 1)],
                route: null,
                currentIndex: setupState.currentIndex + 1});
        }
    };

    const selectWaypoint = (index: number) => {
        setSetupState(oldState => ({...oldState, currentIndex: index}));
    };

    const onWaypointDragged = (index: number, event: google.maps.MapMouseEvent) => {
        setSetupState(oldState => ({
            ...oldState,
            waypoints: [
                ...setupState.waypoints.slice(0, index),
                event.latLng!,
                ...setupState.waypoints.slice(index + 1)],
            route: null,
        }))
    };

    const deleteCurrentWaypoint = () => {
        setSetupState(oldState => ({
            ...oldState,
            waypoints: [
                ...setupState.waypoints.slice(0, oldState.currentIndex),
                ...setupState.waypoints.slice(oldState.currentIndex + 1)],
            currentIndex: oldState.currentIndex > 0 && oldState.currentIndex >= oldState.waypoints.length - 1
                            ? oldState.currentIndex - 1
                            : oldState.currentIndex,
            route: null,
        }))
    };

    const pendingDirections = () => setupState.route === null && setupState.waypoints.length >= 2;

    useEffect(() => {
        if (pendingDirections()) {
            const service = new google.maps.DirectionsService();
            service.route(
                {
                    origin: setupState.waypoints[0],
                    destination: setupState.waypoints.slice(-1)[0],
                    waypoints: setupState.waypoints.slice(1, -1).map(
                        latLng => ({ location: latLng })),
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (directionsResult, status) => {
                    if (status != google.maps.DirectionsStatus.OK || directionsResult === null) {
                        console.error("Directions error:", status);
                        setSetupState(oldState => ({...oldState, route: []}));
                        toast("Route not possible", 4000);
                    } else {
                        console.debug("Directions result:", status, directionsResult);
                        const coords : google.maps.LatLng[] = []
                        directionsResult.routes[0].legs.forEach((leg) => {
                            leg.steps.forEach((step) => {
                                step.path.forEach((c) => {
                                    if (!c.equals(coords[coords.length - 1])) {
                                        coords.push(c);
                                    }
                                })
                            });
                        });
                        setSetupState(oldState => ({...oldState, route: coords}));
                    }
                }
            );
        }
    }, [setupState]);

    const haveValidRoute = () => setupState.route !== null && setupState.route.length > 0 && setupState.waypoints.length > 1;

    return <>
            <IonLoading isOpen={pendingDirections()} message='Calculating exact route, please wait...'/>
            <IonToast position='bottom' message='Tap the map to add first waypoint' isOpen={toastIsVisible}/>
            <IonHeader>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem lines='none'>
                                    <IonButton
                                        color='danger'
                                        fill='solid'
                                        shape='round'
                                        onClick={() => {
                                            setToastVisible(false);
                                            dismiss();
                                        }}
                                    >Cancel</IonButton>
                                    <IonTitle className='ion-text-middle'>Create Route</IonTitle>
                                    <IonButton disabled={!haveValidRoute()} onClick={() => {
                                        saveRoute(setupState)
                                        dismiss()
                                    }}>Finish</IonButton>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <RouteSetupMap {...initialMapOptions} onClick={onMapClick}>
            {setupState.waypoints.map((waypoint, index) => {
                return <RouteSetupMarker
                    key={'waypoint_marker_' + index}
                    position={waypoint}
                    //icon={index == setupState.currentIndex ? activeMarkerIcon : inactiveMarkerIcon}
                    icon={{
                        //path: "M 0 0 l 10,-10 v -20 h -20 v 20 Z",
                        path: "M 11.641666,-23.283334 C 11.641666,-16.853819 4.2487828,-4.248783 0,0 -4.2472904,-4.247291 -11.641667,-16.853819 -11.641667,-23.283334 -11.641667,-29.712848 -6.4295148,-34.925 0,-34.925 c 6.4295148,0 11.641666,5.212152 11.641666,11.641666 z",
                        //anchor: new google.maps.Point(-11, 0),
                        labelOrigin: new google.maps.Point(0, -20),
                        fillOpacity: 0.75,
                        fillColor: index == setupState.currentIndex ? "red" : "blue",
                        strokeColor: "black",
                        strokeWeight: 1,
                        //scale: 4,
                        }}
                    draggable={true}
                    onClick={() => { selectWaypoint(index); }}
                    onDragEnd={event => { onWaypointDragged(index, event); }}
                    onDragStart={() => { selectWaypoint(index); }}
                    //label={index >= setupState.waypoints.length - 1 ? 'X' : ''}
                    label={(index + 1).toString()}
                    title={`Waypoint ${index + 1} / ${setupState.waypoints.length}`}
                />;
            })}
            {setupState.route !== null
             && setupState.route.length > 0
             && <RouteSetupPolyline
                    clickable={false}
                    path={setupState.route}
                    strokeColor={"blue"}
                    strokeOpacity={0.5}
                />}
            </RouteSetupMap>
            <IonToolbar>
                <IonTitle size='small'>
                    {setupState.waypoints.length <= 0 ? "No waypoints" : `Waypoint ${setupState.currentIndex + 1} / ${setupState.waypoints.length}`}
                </IonTitle>
                <IonButtons slot='end'>
                    <IonButton disabled={setupState.waypoints.length <= 0} onClick={deleteCurrentWaypoint}>
                        <IonIcon slot="icon-only" icon={trashOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </>;
};

export default RouteSetup